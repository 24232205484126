<template>
    <div class="container">
        <h1 v-text="header"></h1>
        <ul class="list-unstyled">
            <li class="media">
                <a href="https://amzn.to/37VnAaS" target="_blank">
                    <img class="mr-3" src="@/assets/cuban-cocktails-100-classic-modern-drinks.jpg" alt="Cuban Cocktails: 100 Classic and Modern Drinks">
                </a>
                <div class="media-body">
                    <h5 class="mt-0 mb-1">Cuban Cocktails: 100 Classic and Modern Drinks</h5>
                </div>
            </li>
            <li class="media">
                <a href="https://amzn.to/3swwLXh" target="_blank">
                    <img class="mr-3" src="@/assets/cocktail-codex.jpg" alt="Cocktail Codex">
                </a>
                <div class="media-body">
                    <h5 class="mt-0 mb-1">Cocktail Codex</h5>
                </div>
            </li>
            <li class="media">
                <a href="https://amzn.to/3r0QPAx" target="_blank">
                    <img class="mr-3" src="@/assets/death-co-modern-classic-cocktails.jpg" alt="Death and Co: Modern Classic Cocktails">
                </a>
                <div class="media-body">
                    <h5 class="mt-0 mb-1">Death & Co: Modern Classic Cocktails</h5>
                </div>
            </li>
            <li class="media">
                <a href="https://amzn.to/3sx5qUP" target="_blank">
                    <img class="mr-3" src="@/assets/smugglers-cove.jpg" alt="Smuggler's Cove: Exotic Cocktails, Rum, and the Cult of Tiki">
                </a>
                <div class="media-body">
                    <h5 class="mt-0 mb-1">Smuggler's Cove: Exotic Cocktails, Rum, and the Cult of Tiki</h5>
                </div>
            </li>
            <li class="media">
                <a href="https://amzn.to/3aZzz9y" target="_blank">
                    <img class="mr-3" src="@/assets/bar-book.jpg" alt="The Bar Book: Elements of Cocktail Technique">
                </a>
                <div class="media-body">
                    <h5 class="mt-0 mb-1">The Bar Book: Elements of Cocktail Technique</h5>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { store } from '@/store.js';
export default {
    data() {
        return {
            header: "Libros",
        }
    },
    mounted() {
        store.isNavOpen = false;
    },
}
</script>

<style scoped>
.list-unstyled .media img {
    width: 64px;
}
.list-unstyled .media {
    margin: 20px 0;
}
.media-body h5 {
    text-align: left;
}
</style>